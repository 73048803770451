$dark-bg: $black;
$dark-content-bg: $gray-800;
$dark-item-text: $gray-200;
$dark-item-anchor: lightblue;
$dark-major-heading: #92999f;
$dark-sub-heading: #92999f;

.authentication {
	@include color-scheme(dark) {
		background-color: $dark-bg;
	}

	img {
		@include color-scheme(dark) {
			// filter: brightness(0) invert(0.7);
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h1,
	.h2,
	.h3,
	.h4,
	.h5,
	.h6 {
		@include color-scheme(dark) {
			color: $dark-sub-heading;
		}
	}

	p,
	a,
	label {
		@include color-scheme(dark) {
			color: $dark-item-text;
		}
	}

	i {
		@include color-scheme(dark) {
			background-color: $gray-700;
			color: $gray-300;
		}
	}

	input,
	input::placeholder,
	input:focus-visible {
		@include color-scheme(dark) {
			background: $gray-600;
			color: $gray-200;
		}
	}
	input {
		@include color-scheme(dark) {
			border-color: $gray-600;
		}
	}
}
